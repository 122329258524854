import { ActionList } from "components/action-dropdown";
import { Button } from "components/button";
import { Cloudinary, CloudinaryVideo } from "components/cloudinary";
import { colors } from "theme/theme";
import { Container } from "components/container";
import { FC, useEffect, useMemo, useState } from "react";
import { formatDate, formatEventDate } from "helpers/date";
import { getSpeakingId, sanitize, unescape } from "helpers/text-processing";
import { Lightbox } from "components/lightbox";
import { Select } from "components/forms/select";
import { Shape } from "components/shape";
import { useLabels } from "helpers/hooks";
import { useLocale } from "helpers/locale";
import BootstrapCard from "react-bootstrap/Card";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import Link from "components/link";
import LinkedCardWrapper from "./LinkedCardWrapper";

import {
  CardProps,
  CloudinaryItemProps,
  CloudinaryVideoProps,
  SelectOption,
} from "constants/types";

const wrapUppercaseWords = (originalString: string, tag: string): string => {
  if (!originalString) return originalString;
  const openingTag = `<${tag} style="hyphens: none;">`;
  const closingTag = `</${tag}>`;

  // Use a regular expression to find all uppercase words
  const regex = /\b([A-Z]+)\b/g;

  // Replace each match with the wrapped match
  return originalString.replace(regex, `${openingTag}$1${closingTag}`);
};

export const Card: FC<CardProps> = ({
  title,
  variant = "vertical",
  entityId = "",
  actionButtons = [],
  cardLabel,
  cardImg,
  imageWrapperClass,
  featuredLogos,
  productLabel,
  actions,
  date,
  html,
  children,
  imageOnly,
  url = null,
  externalUrl = false,
  targetBlank,
  lightbox = null,
  onlyEnglish = false,
  revisions,
  className,
}) => {
  const reverse = variant === "horizontal-reverse";
  const { locale, language } = useLocale();
  const { startDate, endDate } = {
    ...{ startDate: null, endDate: null },
    ...date,
  };
  const [cardImage, setCardImage] = useState<CloudinaryItemProps>({
    ...cardImg,
  });
  const [stateUrl, setStateUrl] = useState<SelectOption>({
    value: url,
    label: language,
  });

  const [open, setOpen] = useState(false);
  const [onlyEnglishBtn] = useLabels(["ui-274", '"(English)"']);
  if (onlyEnglish && actionButtons) {
    actionButtons.map(
      (btn) => (btn.label = btn.label + " (" + onlyEnglishBtn.label + ")"),
    );
  }
  if (lightbox && actionButtons) {
    actionButtons.map(
      (btn) =>
        (btn.onClick = () => {
          setOpen(true);
        }),
    );
  }

  function handleMouseDown() {
    if (window.et_DownloadEvent)
      window._etracker.sendEvent(
        window.et_DownloadEvent(title, stateUrl.label),
      );
  }

  const handleSelectChange = (selectedOption: SelectOption) => {
    if (selectedOption) {
      setStateUrl(selectedOption);
      setCardImage({
        ...cardImg,
        media:
          Object.values(revisions)?.[0][selectedOption.label.toLowerCase()]
            .media[0],
      });
    }
  };

  useEffect(() => {
    setStateUrl({
      label: language,
      value: url,
    });
  }, [language, url]);

  const revisionOptions: SelectOption[] = useMemo(() => {
    if (isEmpty(revisions)) return [];
    let currentLang = false;

    const returnObject = Object.values(revisions)
      .map((revision) => {
        if (language)
          return Object.entries(revision).map(([revisionLanguage, { url }]) => {
            if (revisionLanguage.slice(0, 2) === language) currentLang = true;
            return {
              value: url,
              label: revisionLanguage.slice(0, 2).toUpperCase(),
            };
          });
      })
      .flat();
    if (!currentLang) {
      setStateUrl({
        value: stateUrl.value,
        label: Object.keys(Object.values(revisions)?.[0])?.[0],
      });
      setCardImage({
        ...cardImg,
        media: Object.values(Object.values(revisions)?.[0])?.[0].media[0],
      });
    }
    return returnObject;
  }, [revisions, language, stateUrl.value, cardImg]);

  const changeUrlSelect = revisions && (
    <Select
      value={stateUrl}
      options={revisionOptions}
      handleChange={handleSelectChange}
      className={`action-dropdown react-select__container text-uppercase`}
    />
  );

  const imageNotEmtpy = (image: CloudinaryItemProps) =>
    Object.keys(image).length !== 0 && image.constructor === Object;

  const isThinCard = variant === "horizontal-thin";

  const isEvent = entityId?.startsWith("event");

  const imageContent = imageNotEmtpy(cardImage) && (
    <div
      className={classNames(
        "card-image",
        lightbox && " lightbox-" + lightbox,
        imageWrapperClass,
      )}
      {...(lightbox
        ? {
            onClick: () => {
              setOpen(true);
            },
          }
        : {})}
    >
      {cardImage && Boolean(stateUrl.value) && !isThinCard ? (
        <Link
          href={stateUrl.value}
          passHref={true}
          prefetch={false}
          target={
            stateUrl.value.includes("//") || targetBlank ? "_blank" : "_self"
          }
        >
          <Cloudinary {...cardImage} />
        </Link>
      ) : cardImage && Boolean(stateUrl.value) ? (
        <Cloudinary {...cardImage} />
      ) : null}
      {cardImage && !stateUrl.value && <Cloudinary {...cardImage} />}
      {cardLabel && (
        <div className={`card-label background-gradient-${cardLabel.variant}`}>
          {cardLabel.title}
        </div>
      )}
      {featuredLogos?.length > 0 && (
        <div className="card-feature">
          {featuredLogos.slice(0, 2).map((featuredLogo) => (
            <Cloudinary
              {...featuredLogo}
              key={featuredLogo.media?.label}
              width={100}
              height={75}
            />
          ))}
        </div>
      )}
      {productLabel && (
        <div
          className={`product-label product-label-position-${productLabel.position}`}
        >
          {productLabel.title}
        </div>
      )}
      {lightbox === "video" && (
        <Shape variant="play-circle" fill="white" size={54}></Shape>
      )}
      {lightbox === "image" && (
        <Shape variant="search-plus" fill="white" size={54}></Shape>
      )}
      {lightbox && (
        <Lightbox
          heading={{
            title: cardImage.media?.title,
            stylingLevel: "h4",
          }}
          headingType="primary"
          background="grey-triangle"
          size="large"
          open={open}
          setOpen={setOpen}
        >
          {cardImage && lightbox === "image" && (
            <Cloudinary {...{ ...cardImage, width: 1920, ar: null }} />
          )}
          {cardImage && lightbox === "video" && open && (
            <Container>
              <CloudinaryVideo {...(cardImage as CloudinaryVideoProps)} />
            </Container>
          )}
        </Lightbox>
      )}
    </div>
  );

  return (
    <>
      {imageOnly ? (
        imageContent
      ) : (
        <LinkedCardWrapper
          href={stateUrl.value}
          target={targetBlank ? "_blank" : undefined}
          isThinCard={isThinCard}
        >
          <BootstrapCard
            className={classNames(
              variant,
              (!stateUrl.value || isThinCard || isEvent) && "no-hover",
              className,
            )}
            id={
              cardImage && lightbox === "video"
                ? getSpeakingId({
                    title: cardImage?.media?.title || title,
                  })
                : getSpeakingId({ title })
            }
            itemScope
            {...(isEvent && {
              itemType: "http://schema.org/Event",
            })}
            {...(entityId.startsWith("news") && {
              itemType: "http://schema.org/NewsArticle",
            })}
            {...(entityId.startsWith("job") && {
              itemType: "http://schema.org/JobPosting",
            })}
          >
            {!reverse && imageContent}
            {title || html || children ? (
              <BootstrapCard.Body>
                {startDate && (
                  <time className={isEvent ? "event-slider-date" : "card-date"}>
                    {isEvent && startDate && endDate
                      ? formatEventDate(startDate, endDate, locale)
                      : startDate && formatDate(startDate, locale)}
                    {startDate && !isNaN(new Date(startDate).getTime()) && (
                      <meta
                        itemProp={
                          entityId.startsWith("news")
                            ? "datePublished"
                            : "startDate"
                        }
                        content={
                          new Date(startDate).toISOString().split("T")[0]
                        }
                      />
                    )}
                    {endDate && !isNaN(new Date(endDate).getTime()) && (
                      <meta
                        itemProp="endDate"
                        content={new Date(endDate).toISOString().split("T")[0]}
                      />
                    )}
                  </time>
                )}
                {title && stateUrl.value && !externalUrl && !isThinCard ? (
                  <Link
                    href={!lightbox ? stateUrl.value : null}
                    passHref={true}
                    prefetch={false}
                    target={targetBlank ? "_blank" : undefined}
                    {...(lightbox
                      ? {
                          onClick: () => {
                            setOpen(true);
                          },
                        }
                      : {})}
                  >
                    <p
                      className={classNames(
                        isEvent
                          ? "event-slider-title glbl-hover"
                          : "card-title h5 hyphenate",
                      )}
                      dangerouslySetInnerHTML={{
                        __html: wrapUppercaseWords(sanitize(title), "span"),
                      }}
                    />
                  </Link>
                ) : title && stateUrl.value && externalUrl && !isThinCard ? (
                  <a
                    href={!lightbox ? stateUrl.value : null}
                    target="_blank"
                    rel="noreferrer"
                    itemProp="url"
                    {...(lightbox
                      ? {
                          onClick: () => {
                            setOpen(true);
                          },
                        }
                      : {})}
                  >
                    <p
                      itemProp={entityId.startsWith("job") ? "title" : "name"}
                      className={classNames(
                        isEvent
                          ? "event-slider-title glbl-hover"
                          : "card-title h5 hyphenate",
                      )}
                      dangerouslySetInnerHTML={{
                        __html: wrapUppercaseWords(sanitize(title), "span"),
                      }}
                    />
                  </a>
                ) : title && !stateUrl.value && isThinCard ? (
                  <p
                    className="card-title h5 hyphenate"
                    dangerouslySetInnerHTML={{
                      __html: wrapUppercaseWords(sanitize(title), "span"),
                    }}
                  />
                ) : (
                  <p
                    itemProp="name"
                    className={classNames(
                      isEvent
                        ? "event-slider-title glbl-hover"
                        : "card-title h5 hyphenate",
                    )}
                    dangerouslySetInnerHTML={{
                      __html: wrapUppercaseWords(sanitize(title), "span"),
                    }}
                  />
                )}

                <div
                  className="content hyphenate"
                  {...(isEvent && {
                    className: "event-slider-location",
                    itemProp: "location",
                  })}
                  {...(entityId.startsWith("news") && {
                    itemProp: "description",
                  })}
                  {...(entityId.startsWith("job") && {
                    itemProp: "jobLocation",
                  })}
                >
                  {html && (
                    <div
                      {...(entityId.startsWith("job") && {
                        itemScope: true,
                        itemType: "http://schema.org/Place",
                      })}
                      dangerouslySetInnerHTML={{
                        __html: sanitize(unescape(html)),
                      }}
                    ></div>
                  )}
                  {children}
                </div>
              </BootstrapCard.Body>
            ) : null}
            {(actionButtons && actionButtons.length > 0) || actions ? (
              <BootstrapCard.Footer
                className={changeUrlSelect ? "inline-actions" : null}
              >
                <ActionList actions={actions} />
                {changeUrlSelect}
                {actionButtons && actionButtons.length > 0 && !isThinCard ? (
                  actionButtons.map((button, index) => (
                    <Button
                      key={index}
                      {...button}
                      onMouseDown={revisions ? handleMouseDown : null}
                    />
                  ))
                ) : (
                  <Shape variant="caret-right" fill={colors.gray80} />
                )}
              </BootstrapCard.Footer>
            ) : null}
            {reverse && imageContent}
          </BootstrapCard>
        </LinkedCardWrapper>
      )}
    </>
  );
};
